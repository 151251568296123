import React, { useState } from 'react';
import axios from 'axios';
import './App.css'; // Stelle sicher, dass du die CSS-Datei importierst

function App() {
  const [searchPageIds, setSearchPageIds] = useState('');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  function trimAfterTripleBracket(inputString) {
    const pattern = "]}]}";
    const bracketIndex = inputString.indexOf(pattern);
    if (bracketIndex !== -1) {
      // Add the length of the pattern to include ']}}' in the result
      return inputString.substring(0, bracketIndex + pattern.length);
    }
    // If ']}}' is not found, return the original string
    return inputString;
  }
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('https://meta-api.grafana.enghost.de/process_ads', {
        search_page_ids: searchPageIds,
      });
      console.log(response.data)
      console.log(typeof response.data)
      console.log(trimAfterTripleBracket(response.data))
      setData(JSON.parse(trimAfterTripleBracket(response.data)));
      console.log(data)
    } catch (error) {
      console.error('Error fetching data: ', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={searchPageIds}
          onChange={(e) => setSearchPageIds(e.target.value)}
          placeholder="Enter search page ids"
        />
        <button type="submit">Submit</button>
      </form>
      <div className='loading-container'>{loading && <p>Loading...</p>}</div>
      <div className='cards-container'>
      
      {data && data.ad_snapshot_urls.map((url, index) => (
  <div key={index} className="card">
    <p>Ad Snapshot URL: <a href={url} target="_blank" rel="noopener noreferrer">View Ad</a></p>
    {data.video_urls[index] && (
      <video controls width="250">
        <source src={data.video_urls[index]} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    )}
    <p><strong>Transcribed Text</strong>: {data.transcribed_texts[index]}</p>
    {data.analysed_text[index] && (
      <div>
        <strong>Analyzed Text:</strong>
        <div style={{ textAlign: 'left' }}>
          <p><strong>Hook:</strong> {data.analysed_text[index].hook.join(', ')}</p>
          <p><strong>USP:</strong> {data.analysed_text[index].usp.join(', ')}</p>
          <p><strong>Pain Points:</strong> {data.analysed_text[index]["pain points"].join(', ')}</p>
          <p><strong>Solution:</strong> {data.analysed_text[index].solution.join(', ')}</p>
          <p><strong>Call to Action:</strong> {data.analysed_text[index]["call to action"].join(', ')}</p>
        </div>
      </div>
    )}
  </div>
))}
      </div>

    </div>
  );
}

export default App;
